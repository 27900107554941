import { DesignerController } from "./DesignerController";
import { Alignment, BlockedActions, Bounds, Box, FontObject, MCISpacingError, RectOrientation, RenderMode, SaveDataDate, SaveDataElement, SaveDataImage, SaveDataRect, SaveDataText } from "./TypescriptInterfaces";
import { Utility, ElementError, SelectedDragOffset } from "./Utility";
//Canvas Elemente zum Bauen von Produkten
class DesignerElement{
    controller: DesignerController;
    type: string;
    x = 0;
    y = 0;
    w = 0;
    h = 0;
    boundingBox:ElementBounds = new ElementBounds(0,0,0,0);
    id: number;
    patternID: string;
    align: Alignment;
    blockedActions: BlockedActions[] = [];
    static = false;
    error = false;
    errorType: ElementError;
    MCISpacingErrors: MCISpacingError[];
    MCIGroup: DesignerElement[] = [];
    preScale: any = null;
    changed: boolean = true;
    color: string | null;
    rotation: number = 0;

    constructor(controller: DesignerController, x: number, y:number){
        this.x = x;
        this.y = y;
        this.controller = controller;
        this.id = this.controller.idStep;
        this.controller.idStep ++;
        this.color = "#000000";
    }

    //Add this elemenent to canvas
    addElement(history = true, localSave = true, checkBounds = true, autoCenter = false){
        this.controller.elements.push(this);
        if(autoCenter){
            this.controller.centerContent();
        }
        if(checkBounds){
            this.moveRelative(0,0, history, true, false);
        }
        this.controller.update(true, history, localSave);
    }

    /*Move element with various checks:
        Update: Call canvas update
        History: Add history point
        ignoreAlign: Ignore element alignment for movement
        autoCenter = Automatically center the canvas content after movement
    */
    move(x: number, y: number, update = true, history = true, ignoreAlign = false, autoCenter = false){

        //If static, ignore movement
        if(this.static){
            return;
        }

        //Poistion delta
        let xd = x-this.x;
        let yd = y-this.y;

        //No padding
        let area = this.controller.editAreaSize(true);

        let editArea = this.controller.editAreaSize();

        //Grid snapping
        if(this.controller.showGrid == true && this.controller.snapToGrid && (xd != 0 || yd != 0) ){
            let spacing = this.controller.gridSpaceing*this.controller.config.pixelToMM;
            let xround = Math.round((x-this.controller.config.rulerX)/spacing)*spacing+this.controller.config.rulerX;
            let yround = Math.round((y-this.controller.config.rulerY)/spacing)*spacing+this.controller.config.rulerY;
            x = xround;
            y = yround;
        }

        let padding = this.controller.canvasPadding();
        let center = this.controller.editAreaCenter();

        /* BOUND ALIGN */

        let boundsBefore = this.controller.calcBounds(this.controller.canvas);
        

        this.calculateRotatedBoundingBox();
        let elemenentBounds = this.getBounds();

        //Poistion delta
        xd = x-this.x;
        yd = y-this.y;

        //Dont snap to sides when centering
        if(this.align == "centerH"){
            //When larger than edit area (out of bounds)
            if(elemenentBounds.w > editArea.w){
                this.x = center.x;
            }else{
                this.x = x;
            }
        }else{
            //Check for out of bounds horizontal
            //Right
            if(elemenentBounds.x+elemenentBounds.w+xd > area.maxX && !this.blockedActions.includes("move")){
                this.x = area.maxX-(elemenentBounds.w/2);
            }else{
                //Left
                if(elemenentBounds.x+xd < area.minX && !this.blockedActions.includes("move")){
                    this.x = area.minX + (elemenentBounds.w/2);
                }else{
                    this.x = x;
                }
            }
        }

        //Dont snap to sides when centering
        if(this.align == "centerV"){
            // When still out of bounds
            if(elemenentBounds.h > editArea.h){
                this.y = center.y;
            }else{
                this.y = y;
            }
        }else{
            //Check for out of bounds vertical
            if(elemenentBounds.y+elemenentBounds.h+yd > area.maxY){
                this.y = area.maxY-elemenentBounds.h/2;
            }else{
                if(elemenentBounds.y+yd < area.minY){
                    this.y = area.minY+(elemenentBounds.h/2);
                }else{
                    this.y = y;
                }
            }
        }

        elemenentBounds = this.getBounds();
        let actualBounds = this.controller.calcBounds(this.controller.canvas);

        //Overwrite position if element is outside of canvas
        if( this.align && ignoreAlign == false && !(this.blockedActions.includes("move")) ){
            let alignPos = this.getAlginmentPosition(elemenentBounds, actualBounds);

            this.x = alignPos.x;
            this.y = alignPos.y;
        }


        if(autoCenter){
            let newBounds = this.controller.calcBounds(this.controller.canvas);
            this.controller.autoCenter(boundsBefore, newBounds);   
        }

        if(update){
            this.controller.update(false, history);
        }
    }

    movePush(x: number, y: number, moveAmount: number, startElement: DesignerText, excludeList: DesignerElement[] = []): boolean{
        let padding = Math.ceil(this.controller.config.pixelToMM * 0.66);

        //Add initial element
        if(y == 0){
            excludeList.push(this);
        }

        if(y != 0){
            this.moveRelative(x,y,false,true,false,false);
        }

        //Move touching elements to make space for new size
        let bounds = this.getBounds();
        let area = this.controller.editAreaSize(true);
        for(let otherElement of this.controller.elements){
            if(otherElement == this || !(otherElement instanceof DesignerText)){
                continue;
            }
            if(otherElement instanceof DesignerDate){
                continue;
            }
            if( excludeList.includes(otherElement)){
                continue;
            }

            let otherBounds = otherElement.getBounds();
            let dist = Math.abs(this.y - otherElement.y) - (bounds.h/2 + otherBounds.h/2);

            if( dist <= padding || bounds.checkOverlap(otherBounds) ){
                excludeList.push(otherElement);

                //Negative = below
                //Positive = above
                let signStart = Math.sign(startElement.y - otherElement.y);

                let overlap = 0;

                
                if(signStart === 1){
                    //Move up
                    //Distance from bottom of other element to top of resized element
                    overlap = bounds.y - (otherBounds.y + otherBounds.h);
                    overlap = Math.min(overlap, -padding);
                }else{
                    //Move down
                    //Distance from top of other element to bottom of resized element
                    overlap = (bounds.y + bounds.h) - otherBounds.y;
                    overlap = Math.max(overlap, padding);
                }

                let move = moveAmount * signStart * -1;

                if(otherBounds.y + otherBounds.h + move > area.maxY || otherBounds.y + move < area.minY){
                    console.log("Cant fit push: " + move);
                    otherElement.moveRelative(0, move, false, true, false, false);
                    continue;
                }

                otherElement.movePush(0, move, moveAmount, startElement, excludeList);
            }
        }

        return true;
    }

    movePull(x: number, y: number, moveAmount: number, startElement: DesignerText, excludeList: DesignerElement[] = []): boolean{
        let range = moveAmount + this.controller.config.pixelToMM * 4;

        //Add initial element
        if(y == 0){
            excludeList.push(this);
        }

        //Pull nearby elements
        let bounds = this.getBounds();
        let area = this.controller.editAreaSize(true);
        for(let otherElement of this.controller.elements){
            if(otherElement == this || !(otherElement instanceof DesignerText)){
                continue;
            }
            if(otherElement instanceof DesignerDate){
                continue;
            }
            if( excludeList.includes(otherElement)){
                continue;
            }

            let otherBounds = otherElement.getBounds();
            
            //Negative = below
            //Positive = above
            let signStart = Math.sign(startElement.y - otherElement.y);

            let dist = Math.abs(this.y - otherElement.y) - (bounds.h/2 + otherBounds.h/2);
            
            
            console.log("Dist", dist, dist / this.controller.config.pixelToMM , range, range / this.controller.config.pixelToMM , otherElement.text);
        
            if(Math.abs(dist) <= range){
                excludeList.push(otherElement);

                let move = moveAmount * signStart;

                console.log("Move", signStart, moveAmount, move, otherElement.text);
                otherElement.movePull(0, move, moveAmount, startElement, excludeList);
            }
        }

        if(y != 0){
            this.moveRelative(x,y,false,true,false,false);
        }

        return true;
    }

    getAlginmentPosition(elementBounds: ElementBounds, bounds: Bounds): {x: number, y: number}{
        let x = this.x;
        let y = this.y;

        let noBorder = bounds.noBorder;

        if(this.align == "left"){
            x = noBorder.minX + (elementBounds.w/2);
        }
        if(this.align == "centerH"){
            x = ( (noBorder.w) / 2 ) + noBorder.minX;
        }
        if(this.align == "right"){
            x = noBorder.maxX-(elementBounds.w/2);
        }
        if(this.align == "top"){
            y = noBorder.minY + (elementBounds.h/2);
        }
        if(this.align == "centerV"){
            y = ( (noBorder.h-elementBounds.h)/2) + noBorder.minY;
        }
        if(this.align == "bottom"){
            y = noBorder.maxY-(elementBounds.h/2);
        }

        return {
            x: x,
            y: y
        }
    }

    //Move but relative to elements current posiiton (see: move)
    moveRelative(x: number, y: number, history = true, ignoreAlign = false, update = true, autoCenter = false){
        this.move(this.x+x,this.y+y, update, history, ignoreAlign, autoCenter);
    }

    //Default render function
    render(context: CanvasRenderingContext2D, renderMode:RenderMode = RenderMode.Designer){
        //empty default render
    }

    //Show select outline for element
    renderSelected(context: CanvasRenderingContext2D){

        let boundingBox = this.getBounds();

        if(this.MCIGroup.length > 0){
            boundingBox = this.getMCIGroupBounds();
        }

        context.lineWidth = 1.5;
        context.setLineDash([5,3]);
        context.strokeStyle = "#666666";
        context.strokeRect(boundingBox.x, boundingBox.y, boundingBox.w, boundingBox.h);
        context.setLineDash([]);
    }

    setRotation(rotation: number){
        this.rotation = rotation;
        this.calculateRotatedBoundingBox();
    }

    calculateRotatedBoundingBox(){
        let radians = (Math.PI/180)*this.rotation;

        let w = this.w;
        let h = this.h;

        if(this instanceof DesignerDate){
            w = this.rectW;
            h = this.rectH;
        }

        
        

        this.boundingBox.h = w * Math.abs(Math.sin(radians)) + h * Math.abs(Math.cos(radians));
        this.boundingBox.w =  w * Math.abs(Math.cos(radians)) +  h * Math.abs(Math.sin(radians));

        let xCenter = this.x;
        let yCenter = this.y;

        if(this instanceof DesignerText){
            if(this.underline){
                //yCenter += this.getUnderlineSize() / 2;   
            }
        }

        this.boundingBox.x = xCenter - this.boundingBox.w;
        this.boundingBox.y = yCenter - this.boundingBox.h;

        //Offset for text 
        if(this instanceof DesignerText){
            this.boundingBox.y += this.baselineHeight / 2;
        }
    }

    getBounds(): ElementBounds{
        this.calculateRotatedBoundingBox();
        let bounds:ElementBounds = new ElementBounds(this.x, this.y, this.w, this.h);
        
        if(this.boundingBox.w > 0 && this.boundingBox.h > 0){
            bounds.x = this.boundingBox.x + this.boundingBox.w/2;
            bounds.y = this.boundingBox.y + this.boundingBox.h/2;
            bounds.w = this.boundingBox.w;
            bounds.h = this.boundingBox.h;
        }
        return bounds;
    }

    getMCIGroupBounds(): ElementBounds{

        let boundingBox = this.getBounds();

        let minX = boundingBox.x;
        let maxX = boundingBox.x+boundingBox.w;
        let minY = boundingBox.y;
        let maxY = boundingBox.y+boundingBox.h;

        for(let otherElement of this.MCIGroup){
            let otherBounds = otherElement.getBounds();
            minX = Math.min(minX, otherBounds.x);
            maxX = Math.max(maxX, otherBounds.x+otherBounds.w);
            minY = Math.min(minY, otherBounds.y);
            maxY = Math.max(maxY, otherBounds.y+otherBounds.h);
        }

        return new ElementBounds(minX, minY, maxX-minX, maxY-minY);
    }

    //Show error outline around element
    renderError(context: CanvasRenderingContext2D){
        let boundingBox = this.getBounds();

        context.lineWidth = 1.5;
        context.setLineDash([5,3]);
        context.strokeStyle = "orange";
        context.strokeRect(boundingBox.x, boundingBox.y, boundingBox.w, boundingBox.h);
        context.setLineDash([]);

        //Draw red lines between object and other object it's too close to (MCI)
        if(this.MCISpacingErrors && this.MCISpacingErrors.length > 0 && this.errorType == ElementError.MCISpacing){
            for(let error of this.MCISpacingErrors){
                /*
                let other = error.otherElement;
                let otherBoundingBox = other.getBounds();
                let deltaX = this.x - other.x;
                let deltaY = this.y - other.y;*/
                let deltaX = error.distX;
                let deltaY = error.distY;

                let renderH = true;
                let renderW = true;

                if(error.otherElement != null){
                    let other = error.otherElement;
                    let otherBoundingBox = other.getBounds();
                    if(boundingBox.w > otherBoundingBox.w){
                        renderW = false;
                    }
                    if(boundingBox.h > otherBoundingBox.h){
                        renderH = false;
                    }
                    deltaX *= Math.sign(this.x - other.x);
                    deltaY *= Math.sign(this.y - other.y);
                }

                context.fillStyle = "#ff000055";

                /*
                if(error.otherElement instanceof DesignerDate){
                    renderH = true;
                    renderW = true;

                    let padding = error.otherElement.getPadding();
                    if(deltaX != 0){
                        deltaX += Math.sign(deltaX) * padding;
                    }
                    if(deltaY != 0){
                        deltaY += Math.sign(deltaY) * padding;
                    }
                }*/

                if(deltaX != 0 && renderH){
                    //Element left of other
                    if(deltaX < 0){
                        context.fillRect(boundingBox.x + boundingBox.w, boundingBox.y, -deltaX, boundingBox.h);
                    }
                    //Element right of other
                    if(deltaX > 0){
                        context.fillRect(boundingBox.x - deltaX, boundingBox.y, deltaX, boundingBox.h);
                    }
                }
                if(deltaY != 0 && renderW){
                    //Element above other
                    if(deltaY < 0){
                        context.fillRect(boundingBox.x,boundingBox.y + boundingBox.h, boundingBox.w, -deltaY);
                    }
                    //Element below other
                    if(deltaY > 0){
                        context.fillRect(boundingBox.x,boundingBox.y - deltaY, boundingBox.w, deltaY);
                    }
                }
            }
        }
    }

    //Check if element is inside design area
    checkBounds(update = false){
        //this.move(this.x,this.y, update, false, true);
        this.moveRelative(0, 0, false, true, false);
    }

    //Set element error code
    setError(errorType: ElementError){
        if(this.static && errorType != ElementError.OutOutBounds){
            return false;
        }
        this.error = true;
        this.errorType = errorType;
        return true;
    }

    resetMCISpacingErrors(){
        this.MCISpacingErrors = [];
    }

    setMCISpacingError(error: MCISpacingError){
        this.setError(ElementError.MCISpacing);
        this.MCISpacingErrors.push(error);
    }

    //Show warning box next to element
    renderWarningIcon(context: CanvasRenderingContext2D){
        let image = this.controller.warningIcon;
        if(!image){
            return;
        }
        let pos = this.getWarningBox();
        context.drawImage(image,pos.x,pos.y,pos.w,pos.h);
    }

    //Calculate warning box position, making sure it's inside the designer area
    getWarningBox(): Box{
        let editArea = this.controller.editAreaSize();
        let boxSize = Utility.clamp(this.h, 24, 64); 

        let bounds = this.getBounds();

        let diff = bounds.h - boxSize;
        let x = bounds.x - (boxSize + 4);
        let y = bounds.y + diff/2;

        //If not visible left, show right
        if(x < editArea.minX){
            x = bounds.x + bounds.w + 4;
        }

        //If not visible right, show center of element
        if(x+boxSize > editArea.maxX){
            x = this.x;
        }

        //if not visible in center of element, show in center of canvas
        if(x+boxSize > editArea.maxX || x < editArea.minX){
            x = editArea.minX + (editArea.w/2) - (boxSize/2);
        }

        //If not visible in center, show below
        if(y < editArea.minY){
            y = bounds.y + bounds.h + 4;
        }

        //If not visible below, show top
        if(y+boxSize > editArea.maxY){
            y = bounds.y - bounds.h - 4;
        }

        let box:Box = {
            x: x,
            y: y,
            w: boxSize,
            h: boxSize
        }
        
        return box;
    }

    //Safe way to set alignment
    setAlignment(alignment: Alignment, ignoreBlockedActions = true){
        if(ignoreBlockedActions == false && ( this.blockedActions.includes("changeAlignment") || this.controller.config.init.text.changeAlignment != "true") ){
            return;
        }
        this.align = alignment;
    }

    //Align the element to a specific element
    applyAlignment(history = false){
        if(this.align){
            let x1 = this.x;
            let y1 = this.y;
            this.moveRelative(0,0,history);
            if(x1 == this.x && y1 == this.y){
                return false;
            }
            this.changed = true;
            return true;
        }
        return false;
    }

    //Reset alignment of this element
    resetAlignment(){
        this.align = null
    }

    //Set the blocked user actions for this element
    setBlockedActions(blockedActions: BlockedActions[]){
        //If all static properties are set, set the object to static
        if(blockedActions.includes("remove") ){
            //this.static = true;
        }
        if(blockedActions.includes("edit") == true && blockedActions.includes("list") == true && blockedActions.includes("move") == true && blockedActions.includes("select") == true ){
            this.static = true;
        }
        this.blockedActions = blockedActions;
    }

    //Set color (MCI)
    setColor(color: string|null){
        this.color = color;
        this.changed = true;
    }

    //Make this object static (non-changing)
    makeStatic(){
        this.setBlockedActions(["edit", "list", "move", "select"]);
    }

    //Delete this element
    delete(){
        this.controller.deleteElement(this);
    }

    //Compile the save data for JSON storage
    saveData(): SaveDataElement{
        let bounds = this.controller.calcBounds(this.controller.canvas);
        let x = this.x - bounds.minX;
        let y = this.y - bounds.minY;
        
        if(this instanceof DesignerDate){
            let padding = this.getPadding();
            x += padding;
            y += padding;
        }

        return {
            x: Utility.roundDigit(x / this.controller.config.pixelToMM),
            y: Utility.roundDigit(y / this.controller.config.pixelToMM),
            w: Utility.roundDigit(this.w / this.controller.config.pixelToMM),
            h: Utility.roundDigit(this.h / this.controller.config.pixelToMM),
            type: this.type,
            patternID: this.patternID,
            align: this.align,
            blockedActions: this.blockedActions,
            static: this.static,
            color: this.color,
            rotation: this.rotation
        }
    }
}

//Text Element
class DesignerText extends DesignerElement{
    text: string;
    spacedText: string;
    textSpacing: string;
    size: number;
    font: string;
    bold = false;
    italic = false;
    underline = false;
    baselineHeight: number;
    baseSize: number;
    realWidth: number;
    stretch: number;

    constructor(DesignerController: DesignerController, x: number, y:number, text:string, size:number, font:string, align: Alignment = null, rotation: number = 0){
        super(DesignerController,x,y);
        this.type = "text";
        this.text = text;
        this.font = font;
        this.size = size;
        this.align = align;
        this.stretch = 100;
        this.rotation = rotation;
        this.setDimensions();
    }

    //Render element as Text
    render(context: CanvasRenderingContext2D, renderMode:RenderMode = RenderMode.Designer){
        context.globalAlpha = 1;
        context.fillStyle = "black";

        if(this.error){
            context.globalAlpha = 0.33;
        }

        context.font = this.fontString();

        if(renderMode != RenderMode.Production){
            context.fillStyle = this.controller.inkpadColor;
        }
        if(this.color && renderMode != RenderMode.Production){
            context.fillStyle = this.color;
        }

        let underlineSize = 0;
        if(this.underline){
            underlineSize = Math.max( Math.floor( this.getUnderlineSize()) , 1);
        }

        let measure = context.measureText(this.text);
        let lineOffset = measure.actualBoundingBoxAscent;

        let x = Math.round(this.x);
        let y = Math.round(this.y);

        let radians = (Math.PI/180)*this.rotation;
        context.save();
        context.translate(x,y);
        context.rotate(radians);
        context.scale(this.stretch/100,1);

        context.textAlign = 'center';
        context.fillText(this.text , 0, lineOffset/2);



        if(renderMode == RenderMode.Designer){
            if(this.text.length == 0){
                context.setLineDash([10,10]);
                context.strokeRect(this.x,this.y,this.w,this.h);
                context.setLineDash([]);
            }
        }
        if(this.underline){
            underlineSize = Math.max( Math.floor( this.getUnderlineSize()) , 1);

            let underlinePos = lineOffset/2 + this.baselineHeight - underlineSize;

            //Spacing 0.1mm
            underlinePos += this.controller.config.pixelToMM * 0.1;
            
            context.fillRect(-this.w/2, underlinePos, this.w, underlineSize);
        }

        context.restore();
    }

    //Get underline size based on text size
    getUnderlineSize(){
        let fontSize = this.size;
        fontSize = Utility.pt2px(fontSize, this.controller.config.pixelToMM);
        return Math.max(1, fontSize * 0.08 );
    }

    //Safe way to set size
    setSize(size: number, center = true, setDimensions = true, resizeMove = false, ignoreBlockedActions = true){
        //Only apply size changes if not blocked
        if(ignoreBlockedActions == false && (this.blockedActions.includes("scale") || this.controller.config.init.text.changeSize != "true") ){
            return;
        }
        size = Utility.clamp(size, this.controller.config.fontMinSize, this.controller.config.fontMaxSize);

        let oldSize = this.getDimensions();
        let sizeDif = size - this.size;
        this.size += sizeDif;

        if(setDimensions){
            this.setDimensions(false);
        }

        if(resizeMove && !this.controller.MCI){
            let moveAmount = Utility.pt2px(Math.abs(sizeDif), this.controller.config.pixelToMM) / 2;
            moveAmount = Math.ceil(moveAmount);

            if(sizeDif > 0){
                this.movePush(0, 0,moveAmount, this);
            }else{
                
                if(moveAmount > 0){
                    this.movePull(0, 0, moveAmount, this );
                }
            }
        }

        this.controller.startUndoTimer();
        this.changed = true;

        if(center){
            this.controller.centerContent(true);
        }
    }

    //Safe way to set font
    setFont(font: string, center = true, setDimensions = true, ignoreBlockedActions = true){
        if(ignoreBlockedActions == false && ( this.blockedActions.includes("changeFont") || this.controller.config.init.text.changeFont != "true") ){
            return;
        }
        let oldSize = this.getDimensions();
        this.font = font;

        if(setDimensions){
            this.setDimensions(false);
        }
        
        this.controller.startUndoTimer();
        this.changed = true;

        if(center){
            this.controller.centerContent(true);
        }
    }

    //Safe way to set text
    setText(text: string, ignoreBlockedActions = true){
        if(ignoreBlockedActions == false && this.blockedActions.includes("changeText")){
            return;
        }

        let oldWidth = this.getDimensions().w;

        this.text = text;
        let newWidth = this.getDimensions().w;

        let xMove = (oldWidth - newWidth) / 2;

        
        if(this.align == "right"){
            this.moveRelative(xMove, 0, false, true);
        }
        if(this.align == "left" || this.align == null){
            this.moveRelative(-xMove, 0, false, true);
        }
        this.moveRelative(0, 0, false, false, false, true);
        this.setDimensions(false, false);

        this.controller.startUndoTimer();
        this.changed = true;

        this.controller.centerContent(true);

        return true;
    }

    setBold(bold: boolean){
        this.bold = bold;
        this.changed = true;
        //this.setDimensions(false);
    }

    setItalic(italic:boolean){
        this.italic = italic;
        this.changed = true;
        //this.setDimensions(false);
    }

    setUnderline(underline: boolean, ignoreBlockedActions = true){
        if(ignoreBlockedActions == false && this.blockedActions.includes("underline")){
            return;
        }

        let heightBefore = this.h;

        this.underline = underline;
        this.changed = true;
        this.setDimensions(false);

        let heightDiff = this.h - heightBefore;
        //this.moveRelative(0, heightDiff / 2, false, true, false, false);
    }

    //Calculate element dimensions
    getDimensions(){
        this.controller.ctx.font = this.fontString();
        let measure = this.controller.ctx.measureText(this.text);

        let widthActual = measure.actualBoundingBoxLeft + measure.actualBoundingBoxRight;
        let widthContent = measure.width;

        this.realWidth = Math.max(widthActual, widthContent); 
        this.realWidth *= this.stretch/100;

        let w = Math.max(this.realWidth, this.controller.config.textMinWidth);

        //Add slight padding for some edge-cases like OpenSans "J"
        let errorPadding = 1;

        //italic doesnt count in measureText ... rough workaround
        if(this.italic){
            w += this.size/2;
        }

        //Area under textBaseline

        this.baselineHeight = measure.actualBoundingBoxDescent;

        if(this.underline){
            this.baselineHeight += this.getUnderlineSize();   
        }

        //Experimental text measuring
        let h = measure.actualBoundingBoxAscent + this.baselineHeight;
        this.baseSize = h;

        let minMeasure = this.controller.ctx.measureText("a");
        let minH = minMeasure.actualBoundingBoxAscent + minMeasure.actualBoundingBoxDescent;

        h = Math.max(h,minH);

        return {
            w: w,
            h: h+errorPadding
        }
    }

    //Set dimensions of element - based on font and text
    setDimensions(updateList = true, update = true){
        let area = this.controller.editAreaSize();

        let dimensions = this.getDimensions();
        this.w = dimensions.w;

        //Limit to area size
        //this.w = Math.min(this.w, area.w);

        this.h = dimensions.h;
        this.calculateRotatedBoundingBox();
        if(update){
            this.controller.update(updateList, false);
        }
    }

    //Generate CSS Font string
    fontString(){
        //Point -> MM -> Pixel
        let size = this.size;
        size = Utility.pt2px(size, this.controller.config.pixelToMM);
        //this.font = "SophiaRonaldScript";
        //this.bold = true;
        let fontString = (this.italic ? 'italic ' : '')+(this.bold ? 'bold ' : '')+size + "px " + this.font;
        return fontString;
    }

    //Center in canvas
    center(){
        let aligntemp = this.align;
        this.align = "centerH";
        this.moveRelative(0,0);
        this.align = "centerV";
        this.moveRelative(0,0);
        this.align = aligntemp;
    }

    //remove double spaces
    cutSpaces(){
        //regex replace whitespaces
        this.text = this.text.replace(/\s{2,}/g, ' ');
    }

    setStretch(stretch: number){
        this.stretch = stretch;
        this.setDimensions(false);
        this.changed = true;
    }

    //Leter spacing test
    setLetterSpacing(amount: number){
        var spacing = String.fromCharCode(8202);

        //Reset existing spacing to prevent doubeling
        let tempText = this.text;
        tempText = tempText.replaceAll(spacing,"");
        var spacingAmount = "";
        for (var i = 0; i < amount; i++) {
            spacingAmount += spacing;
        }

        tempText = tempText.split("").join(spacingAmount);

        this.text = tempText;
        this.spacedText = tempText;

        this.setDimensions(false);
    }

    //Return data that is required to reconstruct this element
    saveData(dateField = false): SaveDataText | SaveDataElement{
        if(dateField){
            return super.saveData();
        }
        let data:SaveDataText = super.saveData() as SaveDataText;
        data.text = this.text;
        data.size = this.size;
        data.font = this.font;
        data.bold = this.bold;
        data.italic = this.italic;
        data.underline = this.underline;
        data.stretch = Math.round(this.stretch);
        data.h = Utility.roundDigit(this.h / this.controller.config.pixelToMM);
        data.baselineHeight = Utility.roundDigit(this.baselineHeight / this.controller.config.pixelToMM);
        return data;
    }
}

//Date Element
//w,h = text sizes, rect = outline/cutline
//x,y = center
class DesignerDate extends DesignerText{
    rectW: number;
    rectH: number;

    constructor(DesignerController: DesignerController, x: number, y:number, rectW:number, rectH:number, text:string, size:number, font:string){
        super(DesignerController,x,y,text,size,font);

        //Load font
        let fontObject: FontObject = {
            name: font,
            style: {
                normal: font
            }
        }
        this.controller.loadFont(fontObject).then( () => {
            this.setDimensions(false);
        })

        this.type = "date";
        this.rectW = rectW;
        this.rectH = rectH;

        //set padding / cut area
        let padding = this.getPadding();
        this.rectW += padding*2;
        this.rectH += padding*2;

        let area = this.controller.editAreaSize();
    }

    //Calculate date box position
    boxPosition(){
        let x = this.x;
        let y = this.y;
        return {
            x: x,
            y: y
        }
    }

    //Render date box
    render(context: CanvasRenderingContext2D, renderMode:RenderMode = RenderMode.Designer): void {
        let padding = this.getPadding();

        let boxPosition = this.boxPosition();
        boxPosition.x += padding;
        boxPosition.y += padding;
        boxPosition.x = Math.round(boxPosition.x);
        boxPosition.y = Math.round(boxPosition.y);

        

        let radians = (Math.PI/180)*this.rotation;
        context.save();
        context.translate(Math.floor(this.x), Math.floor(this.y));
        context.rotate(radians);

        //Clear area
        context.globalAlpha = 1;
        context.fillStyle = "white";

        context.fillRect(Math.round(-this.rectW/2), Math.round(-this.rectH/2), Math.round(this.rectW), Math.round(this.rectH) );
        //context.clearRect(this.x,this.y,this.rectW,this.rectH);

        //Dater always red and 1px
        context.strokeStyle = "red";

        //Cut line must be pixel perfect!
        context.globalAlpha = 1;
        context.lineWidth = 1;
        if(renderMode != RenderMode.Designer){
            this.rectW = Math.round(this.rectW);
            this.rectH = Math.round(this.rectH);
        }

        let w = this.rectW-(padding*2);
        let h = this.rectH-(padding*2);
        w = Math.round(w);
        h = Math.round(h);

        //Render cut line inside padding, not outisde!
        //Offset by 0.5 to make it pixel perfect, stroke drawing starts at center
        context.strokeRect(-Math.floor(w/2) + 0.5,-Math.floor(h/2) + 0.5, w, h);
        context.restore();

        //this.text = "31. Dez 2023";
        //Only render text placeholder in preview
        if( (renderMode == RenderMode.Designer || renderMode == RenderMode.Preview) && this.text && this.font){

            
            context.fillStyle = "black";
            if(this.color){
                context.fillStyle = this.color;
            }


            //Move text to center
            let realRectW = this.rectW - (padding*2);
            let deltaX = padding+(realRectW/2)-(this.w/2);

            let realRectH = this.rectH - (padding*2);
            let deltaY = padding+(realRectH/2)-(this.h/2);

            //deltaY = this.rectH;

            //this.x -= this.w;
            //this.y -= this.h;
            super.render(context);
            //this.x += this.w;
            //this.y += this.h;

            //context.fillRect(0, 0,10,10 );
            //context.fillText(this.text, -this.w/2, this.h/2);

        }

        //DEBUG
        //context.fillRect(this.x,this.y,5,5);
        

    }

    //Get padding size around date element in pixels
    getPadding(){
        return Math.round(this.controller.cutPadding * this.controller.config.pixelToMM);
    }

    //Return data that is required to reconstruct this element
    saveData(): SaveDataDate{
        let padding = Math.round(this.controller.cutPadding * this.controller.config.pixelToMM);

        let data:SaveDataDate = super.saveData(true) as SaveDataDate;
        let rectW = this.rectW - padding*2;
        let rectH = this.rectH - padding*2;
        data.w = Utility.roundDigit(rectW / this.controller.config.pixelToMM);
        data.h = Utility.roundDigit(rectH / this.controller.config.pixelToMM);
        data.text = this.text;
        data.size = this.size;
        data.font = this.font;
        return data;
    }
}

//Image Element
class DesignerImage extends DesignerElement{
    image: HTMLImageElement | HTMLCanvasElement | null = null;
    imageColored: HTMLImageElement | HTMLCanvasElement | null = null;
    greyscale = false;
    url: string;
    loaded = false;
    anchors:Anchor[] = [];
    filling = false;
    resizeAnchors = true;
    minSize = {x: 3, y: 3};
    constructor(DesignerController: DesignerController, x: number, y: number, url: string, clipart = false, color = "#000000", w: number|null = null, h: number|null = null){
        super(DesignerController, x,y);
        this.type = "image";
        this.url = url;
        this.greyscale = clipart;
        this.color = color;

        this.minSize.x = this.controller.config.imageSpawnMinWidth * DesignerController.config.pixelToMM;
        this.minSize.y = this.controller.config.imageSpawnMinHeight * DesignerController.config.pixelToMM;

        if(w && h){
            this.w = w;
            this.h = h;
            
        }else{
            this.w = this.minSize.x;
            this.h = this.minSize.y;
        }

        if(this.resizeAnchors){
            //Top left
            this.anchors.push( new Anchor(this, "topLeft", this.controller.config.imageaAchorResizeSize) );
            //top right
            this.anchors.push( new Anchor(this, "topRight", this.controller.config.imageaAchorResizeSize) );
            //bottom right
            this.anchors.push( new Anchor(this, "bottomRight", this.controller.config.imageaAchorResizeSize) );
            //bottom left
            this.anchors.push( new Anchor(this, "bottomLeft", this.controller.config.imageaAchorResizeSize) );
        }
        
        this.setImage(url, clipart);
    }

    setImage(url: string, clipart: boolean){
        this.loaded = false;
        Utility.loadImage(url).then( image => {
            this.image = image;
            this.loaded = true;

            if(clipart){
                this.loadColorVersion();
            }

            if(this.controller.MCI){
                this.loadGreyscaleVersion();
                this.setColor(this.color);
            }else{
                this.changeToPadColor();
            }
        }, error => {
            console.error("Error loading image: " + url);
        });
    }

    //Load the colored versio of the clipart for preview
    loadColorVersion(){
        let color = Utility.removeHashtag(this.controller.inkpadColor);
        if(color == "000000"){
            return;
        }
        //only cliparts
        let pos = this.url.indexOf('.svg')
        if(pos <= 0){
            return this.changeToPadColor();
        }

        let substring = this.url.slice(0,pos);
        let color_url = substring + "_" + color + '.svg';
        
        Utility.loadImage(color_url).then( 
            image => {
                this.imageColored = image;
            },
            error => {
                //Fallback if colored version not available
                this.changeToPadColor();
            }
        );
    }

    //Attempt to load greyscale version of image if it exists
    loadGreyscaleVersion(){

        let greyscale_url = "";
        //Format #1
        //https://www.stempelcloud24.com/data/files/26109_1.png_e2003c.svg
        let svgIndex = this.url.indexOf('.svg');
        let pngIndex = this.url.indexOf('.png_');
        //Remove .png and color code to get greyscale version
        if(svgIndex > 0 && pngIndex > 0){
            //Try finding SVG version first
            let substring = this.url.slice(0,pngIndex);
            greyscale_url = substring + '.svg';
        }else{
            if(!this.color){
                return;
            }
            //Format #2
            let color = Utility.removeHashtag(this.color);
            greyscale_url = this.url.replace(color, '000000');
        }

        if(greyscale_url == ""){
            return;
        }

        Utility.loadImage(greyscale_url).then( 
            image => {
                this.imageColored = this.image;
                this.image = image;
                this.url = greyscale_url;
            },
            error => {
                //If SVG version not available, try PNG
                let substring = this.url.slice(pngIndex,svgIndex);
                greyscale_url = this.url.replace(substring, '.png_000000');
                Utility.loadImage(greyscale_url).then( 
                    image => {
                        this.imageColored = this.image;
                        this.image = image;
                    },
                    error => {
                        //If no black and white version available, use colored version
                        this.image = this.imageColored;
                    }
                ).finally( () => {
                    if(this.color != "#000000"){
                        this.setColor(this.color);
                    }
                });
            }
        ).finally( () => {
            if(this.color != "#000000"){
                this.setColor(this.color);
            }
        });

    }

    //Change hue of image to pad color
    changeToPadColor(){
        if(this.controller.MCI){
            return;
        }
        if(this.image == null || this.loaded == false){
            return;
        }
        this.color = this.controller.inkpadColor;
        if(this.color == "#000000"){
            return;
        }

        let canvas = this.renderColoredImage();
        if(canvas){
            this.imageColored = canvas;
        }
    }

    renderColoredImage(uploadRenderedImage = false): HTMLCanvasElement | false{
        if(this.image == null || this.loaded == false || this.color == null){
            return false;
        }

        let w = Math.ceil(this.w);
        let h = Math.ceil(this.h);

        let canvas = document.createElement("canvas");

        canvas.width = Math.ceil(this.image.width);
        canvas.height = Math.ceil(this.image.height);

        //If image is SVG, use maximum canvas size
        if(this.url.indexOf('.svg') > 0){
            canvas.width = w;
            canvas.height = h;
        }

        canvas.style.border = "none";
        let ctx = canvas.getContext('2d');
        if(!ctx){
            return false;
        }
        
        //image
        ctx.drawImage(this.image, 0, 0, canvas.width, canvas.height);

        //Overwrite colors
        ctx.fillStyle = this.color;
        ctx.globalCompositeOperation = "lighter";
        ctx.fillRect(0,0,canvas.width,canvas.height);

        //Restore alpha
        ctx.globalCompositeOperation = "destination-in";
        ctx.drawImage(this.image,0,0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = "source-over";

        if(uploadRenderedImage){
            let imageData = canvas.toDataURL('image/png');
            this.controller.API.saveRenderedImage(this.controller, imageData, this.color, this.url);
        }

        return canvas;
    }

    //Set color (MCI)
    setColor(color: string|null){
        this.color = color;
        //Render colored version
        let canvas = this.renderColoredImage();
        if(canvas){
            this.imageColored = canvas;
        }
    }

    //Resize image proportional based on anchor dragging
    //Real = poisiton on canvas
    //Offset = relative movement
    anchorDragResize(anchorDragging: Anchor, realX: number, realY: number, dragOffset: SelectedDragOffset): boolean{

        let minW = this.minSize.x;
        let minH = this.minSize.y;
        let area = this.controller.editAreaSize(true);
        //Check if mouse is in bounds
        if(realX < area.minX || realY < area.minY || realX > area.maxX|| realY > area.maxY){
            return false;
        }

        if(anchorDragging.corner == "topLeft"){
            let goalW = (this.x+this.w/2) - realX;
            let goalH = (this.y+this.h/2) - realY;

            let scaled = this.controller.calculateAspectRatioFit(this.w,this.h,goalW,goalH);

            if(scaled.width >= minW && scaled.height >= minH){
                this.resize(scaled.width, scaled.height);

                this.y -= scaled.deltaHeight / 2;
                this.x -= scaled.deltaWidth / 2;

                dragOffset.x = realX;
                dragOffset.y = realY;
                this.changed = true;
            }
        }
        if(anchorDragging.corner == "topRight"){
            let goalW = realX - (this.x - this.w/2);
            let goalH = (this.y+this.h/2) - realY;

            let scaled = this.controller.calculateAspectRatioFit(this.w,this.h,goalW,goalH);

            if(scaled.width >= minW && scaled.height >= minH){
                this.resize(scaled.width, scaled.height);
                
                this.y -= scaled.deltaHeight / 2;
                this.x += scaled.deltaWidth / 2;

                dragOffset.x = realX;
                dragOffset.y = realY;
                this.changed = true;
            }
        }
        if(anchorDragging.corner == "bottomRight"){
            let goalW = realX - (this.x - this.w/2);
            let goalH = realY - (this.y - this.h/2);

            let scaled = this.controller.calculateAspectRatioFit(this.w,this.h,goalW,goalH);

            if(scaled.width >= minW && scaled.height >= minH){
                this.resize(scaled.width, scaled.height);

                this.y += scaled.deltaHeight / 2;
                this.x += scaled.deltaWidth / 2;

                dragOffset.x = realX;
                dragOffset.y = realY;
                this.changed = true;
            }
        }
        if(anchorDragging.corner == "bottomLeft"){
            let goalW = (this.x + this.w/2) - realX;
            let goalH = realY - (this.y - this.h/2);

            let scaled = this.controller.calculateAspectRatioFit(this.w,this.h,goalW,goalH);

            if(scaled.width >= minW && scaled.height >= minH){
                this.resize(scaled.width, scaled.height);

                this.y += scaled.deltaHeight / 2;
                this.x -= scaled.deltaWidth / 2;

                dragOffset.x = realX;
                dragOffset.y = realY;
                this.changed = true;
            }
        }
        return true;
    }

    //Chance size of image
    resize(w: number, h: number, uploadRenderedImage = false){
        this.w = w;
        this.h = h;
        this.changed = true;

        //Repalce image with scaled version
        if(this.color != "#000000"){
            let canvas = this.renderColoredImage(uploadRenderedImage);
            if(canvas){
                this.imageColored = canvas;
            }
        }
    }

    //Source: https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
    hexToHSL(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if(!result){
            return;
        }
        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        r /= 255;
        g /= 255;
        b /= 255;

        let max = Math.max(r, g, b)
        let min = Math.min(r, g, b);
        let h;
        let s;
        let l = (max + min) / 2;

        if(max == min){
            // achromatic
            h = 0; 
            s = 0;
        }else{
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            if(h){
                h /= 6;
            }
            
        }
        if(!h){
            return;
        }
        return {
            h: h*360,
            s: s*100,
            l: l*100
        };
    }

    //Render Element as Image - potentially scaled
    render(context: CanvasRenderingContext2D, renderMode:RenderMode = RenderMode.Designer){
        context.globalAlpha = 1;
        context.fillStyle = "black";
        if(this.error){
            context.globalAlpha = 0.33;
        }
        if(!this.image){
            return;
        }

        let image = this.image;
        if(renderMode != RenderMode.Production && this.imageColored && this.color != "#000000"){
            image = this.imageColored;
        }

        if(renderMode != RenderMode.Production){
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = "high";
        }

        //Use full numbers for no aliasing
        let x = Math.round(this.x);
        let y = Math.round(this.y);
        let w = Math.round(this.w);
        let h = Math.round(this.h);

        let wHalf = Math.round(w/2);
        let hHalf = Math.round(h/2);

        context.save();
        context.translate(x,y);

        let radians = (Math.PI/180)*this.rotation;
        context.rotate(radians);

        context.drawImage(image ,-wHalf, -hHalf, w, h);

        context.restore();
    }

    //Render anchors for dragging / rescaling
    renderAnchors(context: CanvasRenderingContext2D){

        if(this.static){
            return;
        }

        let bounds = this.getBounds();

        if(this.anchors.length > 0 && this.controller.input.elementSelected == this){
            for(let anchor of this.anchors){
                anchor.render(context);
            }
        }

        //Info text for size
        let str = (this.boundingBox.w / this.controller.config.pixelToMM).toFixed(1) + "mm X " + (this.boundingBox.h / this.controller.config.pixelToMM).toFixed(1) + "mm";
        context.globalAlpha = 1;
        context.fillStyle = "#333333";
        context.font = "bold 11px Arial";

        let yPos = Math.floor(bounds.y) + bounds.h + 18;
        if(yPos > this.controller.canvas.height){
            yPos = Math.floor(bounds.y) - 8.5;
        }
        context.fillText(str, Math.floor(bounds.x) - 2, yPos);
    }

    //Set dimensions of element - based on image dimensions, clamped to reasonable levels
    clampSize(){
        //Adjust Ratio when over maximum size
        let ih = this.h;
        let iw = this.w;
        let ratioW = ih/iw;
        let ratioH = iw/ih;

        let minW = this.controller.config.imageSpawnMinWidth*this.controller.config.pixelToMM;
        let minH = this.controller.config.imageSpawnMinHeight*this.controller.config.pixelToMM;
        let maxW = this.controller.config.imageSpawnMaxWidth*this.controller.config.pixelToMM;
        let maxH = this.controller.config.imageSpawnMaxHeight*this.controller.config.pixelToMM;

        let area = this.controller.editAreaSize();

        let padding = (2*this.controller.config.pixelToMM);
        maxW = Math.min(maxW,area.w-padding);
        maxH = Math.min(maxH,area.h-padding);

        this.w = Math.max(iw,minW);
        if(this.w > maxW){
            this.w = maxW;
            this.h = this.w / ratioH;
        }else{
            this.h = Math.max(ih,minH);
        }
        
        if(this.h > maxH){
            this.h = maxH;
            this.w = this.h / ratioW;
        }
        this.checkBounds();
    }

    //Center this image based on size
    center(){
        this.x -= this.w/2;
        this.y -= this.h/2;
    }

    //Make the image fill the entire avilable area
    makeFilling(){
        this.makeStatic();
        this.filling = true;
        this.changed = true;
    }

    //adjust poisiton and dimension when filling
    fillUpdate(){
        if(this.filling){
            //this.x = 0;
            //this.y = 0;
        }
    }

    //Return data that is required to reconstruct this element
    saveData(): SaveDataImage{
        let data:SaveDataImage = super.saveData() as SaveDataImage;
        data.url = this.url;
        data.w = Utility.roundDigit(this.w / this.controller.config.pixelToMM);
        data.h = Utility.roundDigit(this.h / this.controller.config.pixelToMM);
        data.greyscale = this.greyscale;
        data.filling = this.filling;
        return data;
    }
}

class DesignerQRCode extends DesignerImage{
    text: string;

    constructor(DesignerController: DesignerController, x: number, y: number, url: string, clipart = false, color = "#000000", w: number|null = null, h: number|null = null){
        super(DesignerController, x,y,url,clipart,color,w,h);
        this.minSize.x = this.controller.config.QRMinWidth * DesignerController.config.pixelToMM;
        this.minSize.y = this.controller.config.QRMinHeight * DesignerController.config.pixelToMM;
    }
}

//Primitive shape
class DesignerRect extends DesignerElement{
    orientation: RectOrientation;

    constructor(DesignerController: DesignerController, x: number, y: number, w:number, h:number, color:string, orientation: RectOrientation){
        super(DesignerController, x,y);
        this.type = "rect";
        this.w = w;
        this.h = h;
        this.color = color;
        this.orientation = orientation;

        if(this.orientation == "vertical"){
            this.setRotation(90);
        }
    }

    render(context: CanvasRenderingContext2D, renderMode:RenderMode = RenderMode.Designer){
        context.fillStyle = "black";
        if(renderMode != RenderMode.Production){
            context.fillStyle = this.controller.inkpadColor;
        }
        if(this.color && renderMode != RenderMode.Production){
            context.fillStyle = this.color;
        }
        //min 1 pixel and at full numbers to avoid blur
        let renderW = Math.max(1,this.w);
        renderW = Math.round(renderW);
        let renderH = Math.max(1,this.h);
        renderH = Math.round(renderH);

        let renderX = Math.round(this.x);
        let renderY = Math.round(this.y);

        let radians = (Math.PI/180)*this.rotation;
        context.save();
        context.translate(renderX, renderY);
        context.rotate(radians);

        context.fillRect(Math.floor(-renderW/2), Math.floor(-renderH/2), renderW, renderH);

        context.restore();
    }

    //Swap width and height for rotation
    rotate(){
        //Swap orientation
        if(this.orientation == "horizontal"){
            this.orientation = "vertical";
        }else{
            this.orientation = "horizontal";
        }
        
        this.setRotation(this.rotation + 90);
    }

    //Return data that is required to reconstruct this element
    saveData(): SaveDataRect{
        let data:SaveDataRect = super.saveData() as SaveDataRect;
        data.w = Utility.roundDigit(this.w / this.controller.config.pixelToMM);
        data.h = Utility.roundDigit(this.h / this.controller.config.pixelToMM);
        data.orientation = this.orientation;
        return data;
    }

    //Set w or h depending on orientation
    setWidth(value: number, center = true){
        let preWidth = this.getWidth();
        value = Utility.clamp(value, this.controller.config.lineMinWidth, this.controller.config.lineMaxWidth);
        value = Utility.roundDigit(value);

        this.h = value;
        this.changed = true;
    }

    //Set w or h depending on orientation
    setLength(value: number, center = true){
        let preLength = this.getLength();
        value = Utility.roundDigit(value);
        this.w = value;

        this.changed = true;
    }

    getWidth(){
        return this.h;

    }

    getLength(){
        return this.w;
    }
}

class ElementBounds{
    x: number;
    y: number;
    w: number;
    h: number;

    constructor(x: number, y: number, w: number, h: number){
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    checkOverlap(bounds: ElementBounds): boolean {
        return this.x < bounds.x + bounds.w &&
            this.x + this.w > bounds.x &&
            this.y < bounds.y + bounds.h &&
            this.y + this.h > bounds.y;
    }
}

type anchorResizePos = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

class Anchor{
    image: DesignerImage
    corner: anchorResizePos;
    size: number;
    constructor(image: DesignerImage, corner:anchorResizePos, size:number){
        this.image = image;
        this.corner = corner;
        
        this.size = size;
    }

    render(context: CanvasRenderingContext2D){
        
        let imageBounds = this.image.getBounds();
        let pos = this.getPosition();
        let padding = 2;
        context.globalAlpha = 1;
        context.fillStyle = "black";
        context.fillRect(imageBounds.x+pos.x - this.size/2 - padding, imageBounds.y+pos.y-this.size/2-padding, this.size+padding*2, this.size+padding*2);
        context.fillStyle = "white";
        context.fillRect(imageBounds.x+pos.x - this.size/2, imageBounds.y+pos.y-this.size/2, this.size, this.size);
    }

    getPosition(){
        let x = 0;
        let y = 0;
        let imageBounds = this.image.getBounds();
        if(this.corner == "topRight"){
            x = imageBounds.w;
        }
        if(this.corner == "bottomLeft"){
            y = imageBounds.h;
        }
        if(this.corner == "bottomRight"){
            x = imageBounds.w;
            y = imageBounds.h;
        }
        return {'x':x, 'y':y}
    }
}

export {DesignerElement, DesignerText, DesignerDate, DesignerImage, DesignerQRCode, DesignerRect, Anchor, Alignment};